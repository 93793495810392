<template>
  <div>
    <b-modal ref="subjectEduPlanItemSelectModal">
      <template v-slot:modal-title>
        Выбрать
      </template>
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th class="text-left">
            {{ $t("educationPlanItem.theme") }}
          </th>
          <th class="text-left">
            {{ $t("educationPlanItem.lessonContent") }}
          </th>
          <th class="text-left">
            {{ $t("educationPlanItem.homeWork") }}
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in planItems" :key="item.id">
          <td>{{ item.theme }}</td>
          <td>{{ item.lessonContent }}</td>
          <td>{{ item.homeWork }}</td>
          <td>
            <b-button
                type="submit"
                @click="choosePlanItem(item)"
                variant="primary"
            >
              Выбрать
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
      <template v-slot:modal-footer>
        <b-button type="reset" @click="closeModal" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import ApiService from "@/common/api.service";

export default {
  name: "PlanSelectModal",
  components: {
  },
  props:{
    groupSubjectId: String,
  },
  data() {
    return {
      planItems:[]
    };
  },
  mounted() {
   //this.reloadData();
  },
  methods: {
    reloadData(groupSubjectId) {
      
      if(groupSubjectId == null){
        groupSubjectId = this.groupSubjectId;
      }
      
      ApiService.querySecured("eduPlans/itemsForSubject", {
        params: {subjectId: groupSubjectId}
      }).then(({data}) => {
        this.planItems = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    openSelectModal(){
      this.$refs['subjectEduPlanItemSelectModal'].show();
    },
    closeModal(){
      this.$refs['subjectEduPlanItemSelectModal'].hide();
    },
    choosePlanItem(item){
      this.$refs['subjectEduPlanItemSelectModal'].hide();
      this.$emit('planItemSelected', item.id);
    }
  }
};
</script>
